import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { Button } from '../../components/Button/styles';
import { Subtitle } from '../../styles/typographyHome';
import FlagsButton from '../../components/FlagsButton';

import { Anchor, Container, Logo } from './styles';

import { useTranslation } from 'react-i18next';

const Home: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  useEffect(() => {
    const params: URLSearchParams = new URLSearchParams(window.location.search);
    const utmSource: string | null = params.get('utm_source');
    const utmMedium: string | null = params.get('utm_medium');
    const utmCampaign: string | null = params.get('utm_campaign');
    const utmTerm: string | null = params.get('utm_term');
    const utmContent: string | null = params.get('utm_content');

    if (utmSource || utmMedium || utmCampaign || utmTerm || utmContent) {
      localStorage.setItem('utm_source', utmSource ?? '');
      localStorage.setItem('utm_medium', utmMedium ?? '');
      localStorage.setItem('utm_campaign', utmCampaign ?? '');
      localStorage.setItem('utm_term', utmTerm ?? '');
      localStorage.setItem('utm_content', utmContent ?? '');
    }
    console.log(window.location.pathname);
  }, []);
  return (
    <Container>
      <Anchor>
        <Logo src="images/logo.png" />
      </Anchor>
      <Subtitle style={{ marginBottom: 40, boxShadow: 'none' }}>
        {t('txt-inicial')} <br />
        <span>{t('sub-inicial')}</span>
      </Subtitle>
      <Button onClick={() => history.push(`/ti/servicos`)}>
        {t('Lets-begin')}
      </Button>
      <FlagsButton />
    </Container>
  );
};

export default Home;
